import React from 'react'

/**
 * Счетчик liveinternet
 * https://jira.rambler-co.ru/browse/TVPROG-388
 */
export const LiveInternet = () => (
  <script
    dangerouslySetInnerHTML={{
      __html: `
  (function(){new Image().src = "//counter.yadro.ru/hit;rambler?r"+
  escape(document.referrer)+((typeof(screen)=="undefined")?"":
  ";s"+screen.width+"*"+screen.height+"*"+(screen.colorDepth?
  screen.colorDepth:screen.pixelDepth))+";u"+escape(document.URL)+
  ";"+Math.random();})();
`
    }}
  />
)
