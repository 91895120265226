import {type LinkType, type SocialLinkType} from '@rambler-components/footer'
import {changeViewport} from 'common/utils/change-viewport'

export const DESKTOP_SOCIAL: SocialLinkType[] = [
  {
    url: 'https://vk.com/rambler',
    code: 'vk'
  },
  {
    url: 'https://ok.ru/rambler',
    code: 'ok'
  },
  {
    url: 'tg://resolve?domain=news_rambler',
    code: 'tg'
  }
]

export const DESKTOP_MENU: LinkType[] = [
  {
    label: 'Мобильная версия',
    onClick: () => changeViewport({isMobile: true})
  },
  {
    label: 'Реклама',
    url: 'https://help.rambler.ru/reklama/?about=1'
  },
  {
    label: 'Помощь',
    url: 'https://help.rambler.ru/feedback/www/'
  },
  {
    label: 'Вакансии',
    url: 'https://ramblergroup.com/career/'
  },
  {
    label: 'Условия использования',
    url: 'https://help.rambler.ru/legal/1430/'
  },
  {
    label: 'Политика конфиденциальности',
    url: 'https://help.rambler.ru/legal/1142/'
  }
]

export const MOBILE_SOCIAL: SocialLinkType[] = [
  {
    url: 'https://vk.com/rambler',
    code: 'vk'
  },
  {
    url: 'https://ok.ru/rambler',
    code: 'ok'
  },
  {
    url: 'tg://resolve?domain=news_rambler',
    code: 'tg'
  }
]

export const MOBILE_LINKS: LinkType[] = [
  {
    label: 'Полная версия',
    onClick: () => changeViewport({isMobile: false})
  },
  {
    label: 'Помощь',
    url: 'https://help.rambler.ru/feedback/www/'
  },
  {
    label: 'Политика конфиденциальности',
    url: 'https://help.rambler.ru/legal/1142/'
  },
  {
    label: 'Условия использования',
    url: 'https://help.rambler.ru/legal/1430/'
  }
]
