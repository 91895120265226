import React, {useEffect} from 'react'
import {observer} from 'mobx-react-lite'

import {
  activateYandexMetrika,
  activateTop100,
  activateTnsCounter
} from '@rambler-id/metrics'
import type {Top100Option} from '@rambler-id/metrics/top100'
import * as sessionStorage from '@rambler-id/session-storage'
import {ErrorBoundary} from '@rambler-id/react'
import {State} from '@rambler-tech/react-toolkit/client'

import {useLocation} from 'react-router-dom'
import type {Stores} from 'stores'
import type {Runtime} from 'types/runtime'
import type {VoteTitleType} from 'types/vote-type'

import {useAuthStore} from 'stores/auth'
import {useYieldToMain} from 'common/utils/yield-to-main'
import {captureException} from 'common/utils/sentry'
import {useRefetchOnPageFocus} from 'common/utils/use-refetch-on-page-focus'
import {
  updateTop100Options,
  TOP100_INIT_OPTIONS_MOBILE,
  TOP100_INIT_OPTIONS_DESKTOP,
  YANDEX_METRICA_GENERAL_PORTAL_INIT,
  YANDEX_METRICA_INIT,
  TNS_SBER_INIT,
  TNS_RAMBLER_INIT,
  ymHit,
  ymHitPortal,
  top100TrackPageview,
  top100TrackPageviewPortal
} from 'common/constants/analytics'
import {FAVORITE_TARGET, SHOW_VOTE} from 'common/constants/session-storage'
import {StateKeys} from 'common/constants'

import {useFirstRender} from 'common/utils/use-first-render'
import {Snackbar, snackbarEvent} from 'common/components/snackbar'
import {ScrollToTopContextProvider} from 'common/components/scroll-to-top'
import {LiveInternet} from 'common/components/live-internet'
import {initRecommender} from 'common/utils/recommender'

import 'styles/global.css'

export interface LayoutProps {
  runtime: Runtime
  stores: Stores
  error: Error | null
  children: React.ReactNode
}

export const LayoutCommon: React.FC<LayoutProps> = observer(
  ({runtime, stores, error, children}) => {
    const {authStore} = useAuthStore()

    useEffect(() => {
      const onLogin = async (): Promise<void> => {
        const vote = sessionStorage.getItem(SHOW_VOTE)

        const favoriteTargetFromSessionStorage =
          sessionStorage.getItem<number>(FAVORITE_TARGET)

        if (favoriteTargetFromSessionStorage) {
          const [key, value] = Object.entries(
            favoriteTargetFromSessionStorage
          )[0]

          if (key === 'channel') {
            await stores.favoriteStore.handleChannelFavoriteChange(value)
          }

          if (key === 'show') {
            await stores.favoriteStore.handleShowFavoriteChange(value)
          }

          sessionStorage.removeItem(FAVORITE_TARGET)
        }

        if (vote) {
          stores.showStore.handleShowVoteChange(vote as VoteTitleType)

          sessionStorage.removeItem(SHOW_VOTE)
        }
      }

      if (authStore.events) {
        const {Events} = authStore.events

        authStore.events.addListener(Events.LOGIN, onLogin)
        authStore.events.addListener(Events.OAUTHLOGIN, onLogin)

        return () => {
          authStore.events?.removeListener(Events.LOGIN, onLogin)
          authStore.events?.removeListener(Events.OAUTHLOGIN, onLogin)
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authStore.events])

    useEffect(() => {
      if (authStore.isAuthorized) {
        updateTop100Options({ramblerId: authStore.user?.chainId})
        stores.favoriteStore.fetchFavoriteChannelIds()
        stores.favoriteStore.fetchFavoriteShowIds()
      } else {
        stores.favoriteStore.clearFavoriteIds()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authStore.isAuthorized])

    useYieldToMain(() => {
      const top100Params: Top100Option =
        runtime.deviceType === 'desktop'
          ? TOP100_INIT_OPTIONS_DESKTOP
          : TOP100_INIT_OPTIONS_MOBILE

      if (top100Params.params) {
        top100Params.params.pub_id = runtime.ruid
      }

      authStore.initialize()
      activateTop100(top100Params)
      activateTnsCounter([TNS_SBER_INIT, TNS_RAMBLER_INIT])
      activateYandexMetrika([
        YANDEX_METRICA_INIT,
        YANDEX_METRICA_GENERAL_PORTAL_INIT
      ])

      initRecommender()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (error) {
        captureException(error, {statusCode: '500'})

        snackbarEvent({
          message: 'Что то пошло не так... Пожалуйста, перезагрузите страницу',
          type: 'error',
          autoCloseDuration: 0,
          multiline: true,
          withCloseButton: false
        })
      }
    }, [error])

    const location = useLocation()
    const isFirstRender = useFirstRender()

    useEffect(() => {
      if (!isFirstRender) {
        ymHit()
        ymHitPortal()
      }

      top100TrackPageview()
      top100TrackPageviewPortal()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    useRefetchOnPageFocus(stores)

    return (
      <>
        <ErrorBoundary
          captureException={captureException}
          fallback={<h1>Что то пошло не так...</h1>}>
          <ScrollToTopContextProvider>{children}</ScrollToTopContextProvider>
        </ErrorBoundary>

        <Snackbar />

        <LiveInternet />

        <State name={StateKeys.RUNTIME} state={runtime} />

        {stores.storesWithStateKeys.map(({stateKey, store}) => (
          <State key={stateKey} name={stateKey} state={store.serialize()} />
        ))}
      </>
    )
  }
)
